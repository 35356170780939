<template>
  <nav class="z-50 flex w-full sticky top-0 bg-light-700 h-[var(--header-height)] text-dark-50">
    <div
      class="w-full lg:container relative mx-auto px-6 sm:px-12 md:px-24 pt-8 pb-16 max-w-9xl flex items-center gap-x-4"
    >
      <a href="/">
        <Logo class="w-24" />
      </a>
      <!-- responsive mobile nav bar -->
      <div
        class="fixed overflow-y-hidden w-full z-40 top-[var(--header-height)] left-0 bg-light-700
        transition-all duration-300
        flex flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-4
        md:left-auto md:mx-0 md:w-auto md:h-auto md:relative md:top-auto md:bg-transparent"
        :class="{
          'h-0': !mobileMenuOpen,
          'h-[calc(100%-var(--header-height))]': mobileMenuOpen,
        }"
      >
        <button
          v-for="item in menuItems"
          :key="item.id"
          class="navbar-link whitespace-nowrap"
          :class="{'active': item.active}"
          @click="(event) => handleClick(event, item)"
        >
          {{ item.title }}
        </button>
      </div>
      <!-- spacer -->
      <div class="grow" />
      <div class="md:hidden">
        <!-- The burger menu button when on small screens. -->
        <button
          class="hover:bg-accentHover py-2 px-3 rounded hover:text-standardtext"
          tabindex="0"
          role="button"
          :aria-label="mobileMenuOpen ? 'Close menu' : 'Open menu'"
          @click.stop="mobileMenuOpen = !mobileMenuOpen"
        >
          <Bars3Icon
            class="w-6 h-6 transition-transform duration-300"
            :class="{
              'rotate-90': mobileMenuOpen
            }"
          />
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
  import { Bars3Icon } from '@heroicons/vue/24/solid'
  import { PropType, ref } from 'vue'

  import Logo from '@/components/Logo.vue'

  const mobileMenuOpen = ref(false)

  type MenuItem = {
    id: string,
    title: string,
    active: boolean,
  }

  const emits = defineEmits<{(e: 'menuItemClick', item: MenuItem): void }>()

  const handleClick = (event: MouseEvent, item: MenuItem) => {
    emits('menuItemClick', item)
  }

  defineProps({
    menuItems: {
      type: Object as PropType<MenuItem[]>,
      required: true,
    },
  })
</script>

<style lang="scss" scoped>
.navbar-link {
  @apply transition-colors duration-200 first:pt-4 last:pb-4 md:first:pt-0 md:last:pb-0;

  &:hover {
    @apply text-primaryBgHover;
  }

  &.active {
    @apply text-primary;
  }
}
</style>
